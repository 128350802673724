import {
    FileDescriptionIcon,
    BellRinging2Icon, InfoCircleIcon, TiltShiftIcon,
    LayoutDashboardIcon, UsersIcon, CalendarTimeIcon, NoteIcon, CalendarEventIcon
} from 'vue-tabler-icons';

let loggerType: any
if (process.client) {
    let logger = JSON.parse(localStorage.getItem("logger"));
    loggerType = logger.user_type
    console.log(loggerType)
}

export interface menu {
    header?: string;
    title?: string;
    icon?: any;
    to?: string;
    chip?: string;
    chipColor?: string;
    chipVariant?: string;
    chipIcon?: string;
    children?: menu[];
    disabled?: boolean;
    type?: string;
    subCaption?: string;
}

const sidebarItem: menu[] = [];

if (loggerType == 2) {
    sidebarItem.push(
        {
            title: 'Dashboard',
            icon: LayoutDashboardIcon,
            to: '/home'
        },

        {
            title: 'My Shifts',
            icon: CalendarEventIcon,
            to: '/home/shifts'
        },
        {
            title: 'Incident Report',
            icon: InfoCircleIcon,
            to: '/home/incidents',
            
        },
        {
            title: 'Shift Notes',
            icon: NoteIcon,
            to: '/home/notes'
        },

        
    )
} else {
    sidebarItem.push(
        {
            title: 'Dashboard',
            icon: LayoutDashboardIcon,
            to: '/dashboard'
        },
        {
            title: 'Clients',
            icon: UsersIcon,
            to: '/clients'
        },
        {
            title: 'Schedule',
            icon: CalendarTimeIcon,
            to: '/schedule'
        },
        {
            title: 'Employees',
            icon: UsersIcon,
            to: '/employee'
        },
        {
            title: 'Employees Shifts',
            icon: BellRinging2Icon,
            to: '/employee-shifts'
        },
        {
            title: 'Shifts',
            icon: TiltShiftIcon,
            to: '/shifts'
        },
        {
            title: 'Incident Report',
            icon: InfoCircleIcon,
            to: '/incident'
        },
        {
            title: 'Shift Notes',
            icon: NoteIcon,
            to: '/shift-note'
        },
    )

}


export default sidebarItem;
