<script setup lang="ts">
import { RouterView } from 'vue-router';
const title = ref("Umusanzu");
useHead({
  meta: [{ content: title }],
  titleTemplate: (titleChunk) => {
    return titleChunk
      ? `New Life Aged and Disability Care`
      : "New Life Aged and Disability Care";
  },
});
</script>

<template>
    <v-locale-provider >
        <v-app>
            <LayoutFullMain/>
            <v-main>
                <v-container fluid class="page-wrapper">
                    <div class="maxWidths">
                        <RouterView />
                    </div>
                    
                </v-container>
                <v-col class="text-center mt-2">
            <p class="text-muted">Design and Developed by <a flat variant="text" href="https://qonics.com/" target="_blank"
                    class="pl-1 text-primary">Qonics Inc</a></p>
        </v-col>
            </v-main>
        </v-app>
    </v-locale-provider>
</template>
